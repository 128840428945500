import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import * as React from 'react';

export const Animated = ({
  children,
  from = {},
  to = {},
  toScrollTrigger,
  style = {},
}: {
  children: React.ReactChild;
  from: Record<string, any>;
  to: Record<string, any>;
  toScrollTrigger: Record<string, any>;
  style: Record<string, any>;
}) => {
  const ref1 = useRef(null);

  useEffect(() => {
    const element = ref1.current;
    gsap.fromTo(
      element,
      {
        ...from,
      },
      {
        scrollTrigger: {
          trigger: element.parentElement,
          toggleActions: 'play pause resume reset',
          ...toScrollTrigger,
        },
        ...to,
      },
    );
  }, []);
  return (
    <div ref={ref1} className="animated" style={style}>
      {children}
    </div>
  );
};
