import React from 'react';
import PW from '../icons/pw.svg';
import IIB from '../icons/iib.svg';
import Appsilon from '../icons/appsilon.svg';
import MIM from '../icons/mim.svg';
import DataLab from '../icons/midatalab.svg';
import XolvIo from '../icons/xolvio.svg';
import ScienceNow from '../icons/sciencenow.svg';
import OffOpera from '../icons/offopera.svg';
import LeanLibrary from '../icons/leanlibrary.svg';
import Stellar from '../icons/stellarfireworks.svg';

export const home = {
  about: {
    title: 'About',
    content:
      'We are a design and development studio, helping organizations tell their stories through data. By merging design, technology and science, we create physical and digital data-based experiences, leading the full process of creative projects, from research and prototyping to scaling.',
  },
  ourWorks: {
    title: 'Our works',
    content: 'Inventive approach to information design.',
    buttonText: 'SHOW ALL PROJECTS',
  },
  whatWeDo: {
    title: 'what we do',
    content:
      'Combining our backgrounds in design, software engineering and data science, we help companies present complex information effectively and find new opportunities to engage their audience. We love transforming numbers into visuals and we do that by creating:',
    sections: [
      {
        title: 'Design',
        tags: [
          'data visualization',
          'branding & communications',
          'infographics',
          'reports',
          'data art',
          'websites & mobile apps',
        ],
      },
      {
        title: 'Software',
        tags: [
          'websites',
          'web apps',
          'dashboards',
          'applications',
          'embedded software',
          'big data',
        ],
      },
      {
        title: 'Data Science',
        tags: [
          'data journalism',
          'data visualization',
          'data analysis',
          'machine learning',
        ],
      },
    ],
  },
  clients: {
    title: 'Our Clients',
    items: [
      <PW />,
      <Appsilon />,
      <MIM />,
      <IIB />,
      <DataLab />,
      <XolvIo />,
      <ScienceNow />,
      <OffOpera />,
      <LeanLibrary />,
      <Stellar />,
    ],
  },
};

export default home;
