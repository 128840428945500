import * as React from 'react';
import { Box, Flex, Link } from 'rebass/styled-components';
import { cta } from '@/cms/cta';
import { HelloButton } from '@/components/Header/HelloButton';
import { styledTheme } from '@/styledTheme';
import { ContentWrapper } from '@/components/ContentWrapper';
import { Title } from '@/components/Title';
import styled from 'styled-components';
import { SocialTMP } from '@/components/SocialTMP';
import { underlineLinks } from '@/utils/underlineLinks';
import { HeroTitle } from '@/components/HeroTitle';
import { TextAnimation } from '@/components/TextAnimation';

export const CTA = () => (
  <Box
    bg={styledTheme.colors.primary}
    pt={['80px', '80px', 5]}
    pb={['80px', '80px', 0]}
  >
    <StyledContentWrpper maxWidth={1000}>
      <HeroTextWrapper
        justifyContent="space-between"
        alignItems="flex-end"
        flexWrap="wrap"
      >
        <Box maxWidth={700}>
          <TextAnimation>
            <HeroTitle color={styledTheme.colors.white}>{cta.text}</HeroTitle>
          </TextAnimation>
        </Box>
        <Flex mb={[0, 0, '30px', 1]} mt={[4, 4, 0]}>
          <HelloButton />
        </Flex>
      </HeroTextWrapper>
    </StyledContentWrpper>

    <StyledHr style={{ background: styledTheme.colors.secondary, margin: 0 }} />

    <ContentWrapper maxWidth={1000} id="contact">
      <Contacts
        justifyContent="space-between"
        alignItems="flex-start"
        flexWrap="wrap"
      >
        <Box width={[1, 1, 1 / 3]} py={[2, 2, 3, 4]}>
          <Title color={styledTheme.colors.secondary} mb={['28px', '28px', 3]}>
            email
          </Title>
          <Flex
            color={styledTheme.colors.white}
            fontSize={[4]}
            css={underlineLinks('white')}
          >
            <Link fontWeight="bold" href={`mailto:${cta.email}`}>
              {cta.email}
            </Link>
          </Flex>
        </Box>
        <Box py={[2, 2, 3, 4]} pl={[0, 0, 3, 4]} width={[1, 1, 1 / 3]}>
          <Title color={styledTheme.colors.secondary} mb={['28px', '28px', 3]}>
            phone
          </Title>
          <Flex
            color={styledTheme.colors.white}
            fontSize={[4]}
            css={underlineLinks('white')}
          >
            <Link fontWeight="bold" href={`tel:${cta.phone}`}>
              {cta.phone}
            </Link>
          </Flex>
        </Box>
        <Box py={[2, 2, 3, 4]} pl={[0, 0, 3, 4]} width={[1, 1, 1 / 3]}>
          {/* <Title color={styledTheme.colors.secondary} mb={['28px', '28px', 3]}> */}
          {/*  social */}
          {/* </Title> */}
          {/* <SocialTMP /> */}
        </Box>
      </Contacts>
    </ContentWrapper>
  </Box>
);

const Contacts = styled(Flex)`
  > *:nth-child(2) {
    border-left: 1px solid ${styledTheme.colors.secondary};
    border-right: 1px solid ${styledTheme.colors.secondary};
    @media screen and (max-width: ${styledTheme.breakpoints[2]}) {
      border-left: none;
      border-right: none;
    }
  }

  g:hover {
    cursor: pointer;
    pointer-events: bounding-box;
    > path {
      fill: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

const StyledContentWrpper = styled(ContentWrapper)`
  margin-bottom: 120px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin-bottom: 0;
  }
`;

const HeroTextWrapper = styled(Flex)`
  a {
    margin-top: 0;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    flex-direction: column;
    align-items: flex-start;
    a {
      margin-top: 60px;
    }
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    a {
      margin-top: 0;
    }
  }
`;

const StyledHr = styled.hr`
  display: block;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: none;
  }
`;
