import React from 'react';
import { Animated } from './Animated';

export const TextAnimation = ({
  children,
  from,
  to,
  toScrollTrigger,
  style,
}) => (
  <Animated
    from={{
      y: 50,
      opacity: 0,
      ...from,
    }}
    to={{ y: 0, opacity: 1, once: true, ...to }}
    toScrollTrigger={{
      start: 'top 90%',
      scrub: false,
      once: true,
      ...toScrollTrigger,
    }}
    style={{ opacity: 0, ...style }}
  >
    {children}
  </Animated>
);
