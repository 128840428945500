import styled from 'styled-components';
import { Box } from 'rebass/styled-components';

export const LeadingUnderscore = styled(Box)`
  width: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
  margin-top: 8px;
  margin-right: 1px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    margin-right: 1px;
    margin-top: 7px;
    width: 30px;
  }
`;
