import { Heading } from 'rebass/styled-components';
import styled from 'styled-components';

export const HeroTitle = styled(Heading)`
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 50px;
  letter-spacing: -0.01em;
  font-family: ${({ theme }) => theme.fonts.body};

  span {
    font-family: ${({ theme }) => theme.fonts.heading};
    font-size: 40px;
    font-weight: 400;
    letter-spacing: -0.01em;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    font-size: 70px;
    line-height: 70px;

    span {
      font-size: 64px;
    }
  }
`;
