import * as React from 'react';
import { Flex } from 'rebass/styled-components';
import { Title } from '@/components/Title';
import home from '@/cms/home';
import styled from 'styled-components';
import { styledTheme } from '@/styledTheme';
import { ContentWrapper } from './ContentWrapper';

export const Clients = () => (
  <ContentWrapper maxWidth={1000} my={[3, 3, 5]}>
    <Title>{home.clients.title}</Title>
    <ItemsWrapper
      mt={[4]}
      w={1}
      justifyContent="space-between"
      flexWrap="wrap"
      alignItems="center"
    >
      {home.clients.items.map((svg) => (
        <Flex width={[1 / 2, 1 / 2, 'auto']}>{svg}</Flex>
      ))}
    </ItemsWrapper>
  </ContentWrapper>
);

const ItemsWrapper = styled(Flex)`
  > * {
    justify-content: center;
    margin-bottom: 50px;
  }

  @media screen and (min-width: ${styledTheme.breakpoints[1]}) {
    > * {
      justify-content: center;
      margin-bottom: 50px;
      flex-basis: 16%;
      flex: 0 1 calc(20% - 8px);

      &:first-child {
        justify-content: flex-start;
      }
      &:nth-of-type(5) {
        justify-content: flex-end;
      }
      &:nth-of-type(6) {
        justify-content: flex-start;
      }
      &:last-child {
        justify-content: flex-end;
      }
    }
  }

  //  mobile order
  @media screen and (max-width: ${styledTheme.breakpoints[1]}) {
    svg {
      transform: scale(0.9);
      transform-origin: center;
    }

    > *:nth-child(2) {
      order: 6;
    }

    > *:nth-child(4) {
      order: 5;
    }
  }
`;
