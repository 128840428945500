import * as React from 'react';
import styled from 'styled-components';
import { Row } from '@/components/Row';
import { Box, Flex } from 'rebass/styled-components';
import { home } from '@/cms/home';
import { ContentWrapper } from '@/components/ContentWrapper';
import { Text } from '@/components/Text';
import { styledTheme } from '@/styledTheme';
import { LeadingUnderscore } from '@/components/LeadingUnderscore';
import { Animated } from '@/components/Animated';
import { Title } from '../Title';

export const WhatWeDo = () => {
  const data = home.whatWeDo;
  return (
    <StyledContentWrapper maxWidth={1000}>
      <Row
        mb={[4]}
        left={<Title>{data.title}</Title>}
        right={<Text fontSize={[3]}>{data.content}</Text>}
      />
      <hr />
      {data.sections.map((section, index) => (
        <>
          <Box mt={[3]}>
            <Row
              mb={[4]}
              left={
                <Animated
                  from={{
                    x: (index + 1) * 50,
                    opacity: 0,
                  }}
                  to={{
                    opacity: 1,
                    x: 0,
                  }}
                  toScrollTrigger={{
                    scrub: false,
                    once: false,
                  }}
                >
                  <Text
                    fontSize={[5, 5, 6]}
                    fontWeight="bold"
                    lineHeight="48px"
                  >
                    {section.title}
                  </Text>
                </Animated>
              }
              right={
                <Flex flexWrap="wrap">
                  {section.tags.map((t, i) => (
                    <Flex
                      width={[1, 1, 1 / 2]}
                      mb={[1]}
                      fontSize={[2]}
                      color={styledTheme.colors.tag}
                      fontFamily={styledTheme.fonts.heading}
                      lineHeight={['28px']}
                      sx={{
                        transition: '0.2s',
                        ':hover': {
                          color: styledTheme.colors.primary,
                        },
                      }}
                    >
                      <Animated
                        from={{
                          x: 50 * i + 50,
                          opacity: 0,
                        }}
                        to={{
                          x: 0,
                          opacity: 1,
                        }}
                        toScrollTrigger={{
                          delay: i * 5000 + 1000,
                          toggleActions: 'play none none reset',
                        }}
                      >
                        <Flex>
                          <LeadingUnderscore
                            style={{ width: 8, marginBottom: 9 }}
                          />
                          {t}
                        </Flex>
                      </Animated>
                    </Flex>
                  ))}
                </Flex>
              }
            />
            <StyledHr />
          </Box>
        </>
      ))}
    </StyledContentWrapper>
  );
};

const StyledContentWrapper = styled(ContentWrapper)`
  overflow-x: hidden;
`;

const StyledHr = styled.hr`
  @media screen and (max-width: ${styledTheme.breakpoints[1]}) {
    margin-bottom: 38px;
  }
`;
